    $--header-height: 3rem;

    /*========== Colors ==========*/
    /* Change favorite color */
    $--hue-color: 595; /*Purple 250 - Green 142 - Blue 230 - Pink 340*/

    /* HSL color mode */
    $--first-color: hsl($--hue-color, 69%, 61%);
    $--first-color-second: hsl($--hue-color, 69%, 61%);
    $--first-color-alt: hsl($--hue-color, 57%, 53%);
    $--first-color-lighter: hsl($--hue-color, 92%, 85%);
    $--title-color: hsl($--hue-color, 8%, 15%);
    $--text-color: hsl($--hue-color, 8%, 45%);
    $--text-color-light: hsl($--hue-color, 8%, 65%);
    $--input-color: hsl($--hue-color, 70%, 96%);
    $--body-color: hsl($--hue-color, 60%, 99%);
    $--container-color: #fff;
    $--scroll-bar-color: hsl($--hue-color, 12%, 90%);
    $--scroll-thumb-color: hsl($--hue-color, 12%, 80%);

    /*========== Font and typography ==========*/
    $--body-font: 'Poppins', sans-serif;

    /* .5rem = 8px, 1rem = 16px, 1.5rem = 24px ... */
    $--big-font-size: 2rem;
    $--h1-font-size: 1.5rem;
    $--h2-font-size: 1.25rem;
    $--h3-font-size: 1.125rem;
    $--normal-font-size: .938rem;
    $--small-font-size: .813rem;
    $--smaller-font-size: .75rem;

    /*========== Font weight ==========*/
    $--font-medium: 500;
    $--font-semi-bold: 600;

    /*========== Margenes Bottom ==========*/
    /* .25rem = 4px, .5rem = 8px, .75rem = 12px ... */
    $--mb-0-25: 0.25rem;
    $--mb-0-5: 0.5rem;
    $--mb-0-75: 0.75rem;
    $--mb-1: 1rem;
    $--mb-1-5: 1.5rem;
    $--mb-2: 2rem;
    $--mb-2-5: 2.5rem;
    $--mb-3: 3rem;

    /*========== z index ==========*/
    $--z-tooltip: 10;
    $--z-fixed: 100;
    $--z-modal: 1000;

    $--box-shadow: 0px 10px 30px rgba(57, 56, 61, 0.205);

    $--hover-background: #e5e6ec;
    $--color-light: #fff;