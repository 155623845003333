// Variables
@import "variables";


/*==================== BASE ====================*/
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
html{
    scroll-behavior: smooth;
    height: 100vh;
}
body{
    margin: 0 0 #{$--header-height} 0;
    font-family: $--body-font;
    font-size: calc(1rem + .25vh);
    background-color: $--body-color;
    color: $--text-color;
    min-height: 100vh !important;
}

h1,h2,h3,h4{
    color: $--title-color;
    font-weight: $--font-semi-bold;
}
ul{
    list-style: none;
}

a{
    text-decoration: none;
}

img{
    max-width: 100%;
    height: auto;
}

.main {
    min-height: calc( 100vh - 260px) !important;
}

.maingame {
    position: relative;
    display: flex;                 // And you will probably need this too.
    flex-direction: row;
}

/*==================== REUSABLE CSS CLASSES ====================*/
.section{
    padding: 2rem 0 4rem;
}
.section__title{
    font-size: $--h1-font-size;
}

.section__subtitle{
    display: block;
    font-size: $--small-font-size;
    margin-bottom: $--mb-3;
}

.section__title,
.section__subtitle{
    text-align:  center;
}

/*==================== LAYOUT ====================*/
.container{
    max-width: 768px;
    margin-left: $--mb-1-5;
    margin-right: $--mb-1-5;
}

.grid{
    display: grid;
    gap: 1.5rem;
}

.header{
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: $--z-fixed;
    background-color: $--body-color;
}

/*==================== NAV ====================*/
.nav{
    max-width: 1140px;
    height: #{$--header-height};
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nav__logo,
.nav__toggle{
    color: $--title-color;
    font-weight: $--font-medium;
}

.nav__logo:hover{
    color: $--first-color;
}

.uil-twitter-x-line::before {
    display: inline-block;
    width: 1em;
    height: 1em;
    content: "";
    background-color: currentColor;
    -webkit-mask: url('data:image/svg+xml;utf8,<svg viewBox="0 -8 26 30" xmlns="http://www.w3.org/2000/svg"><g><path fill="white" d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg>') no-repeat center;
    mask: url('data:image/svg+xml;utf8,<svg viewBox="0 -8 26 30" xmlns="http://www.w3.org/2000/svg"><g><path fill="white" d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg>') no-repeat center;
    background-position: center 3px;
}

.nav__toggle{
    font-size: 1.1rem;
    cursor: pointer;
}

.nav__toggle:hover{
    color: $--first-color;
}

@media screen and (min-width: 968px) {
    $--big-font-size: 3rem;
    $--h1-font-size: 2.25rem;
    $--h2-font-size: 1.5rem;
    $--h3-font-size: 1.25rem;
    $--normal-font-size: 16px;
    $--small-font-size: 1rem;
    $--smaller-font-size: .813rem;

}

@media screen and (max-width: 767px) {
    .nav__menu{
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: $--body-color;
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, .15);
        border-radius: 1.5rem 1.5rem 0 0;
        transition: .3s;
    }
}

.nav__list{
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.nav__link{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: $--normal-font-size;
    color: $--title-color;
    font-weight: $--font-medium;
}

.nav__link:hover{
    color: $--first-color;
}

.nav__icon{
    font-size: 1.2 rem;
}

.nav__close{
    position: absolute;
    right: 1.3rem;
    bottom: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: $--first-color;
}

.nav__close:hover{
    color: $--first-color-alt;
}

/* show menu */
.show-menu{
    bottom: 0;
}

/* Active link */
.active-link{
    color: $--first-color;
}

/* Change background header */
.scroll-header{
    box-shadow: 0 1px 4px rgba(0, 0, 0, .15);
}


/*==================== HOME ====================*/
.home__container{
    gap: 1rem;
}

.home__content{
    grid-template-columns: .5fr 3fr;
    padding-top: 3.5rem;
    align-items: center;
}

.home__social{
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
}
.home__social-icon{
    font-size: 1.25rem;
    color: $--first-color;
}

.home__social-icon:hover{
    color: $--first-color-alt;
}

.home__blob{
    width: 200px;
    fill: $--first-color;
}
.home__blob-img{
    width: 170px;
}

.home__data{
    grid-column: 1/3;
}
.home__title{
    font-size: $--big-font-size;
}

.home__subtitle{
    font-size: $--h3-font-size;
    color: $--text-color;
    font-weight: $--font-medium;
    margin-bottom: $--mb-0-75;
}

.home__description{
    margin-bottom: $--mb-2;
}

.home__scroll{

}

.home__scroll-button{
    color: $--first-color;
    transition: .3s;
}

.home__scroll-button:hover{
    transition: translateY(.25rem);
}
.home__scroll-mouse{
    font-size: 2rem;
}
.home__scroll-name{
    font-size: $--small-font-size;
    color: $--title-color;
    font-weight: $--font-medium;
    margin-right: $--mb-0-25;

}
.home__scroll-arrow{
    font-size: 1.25rem;
}


/*==================== BUTTONS ====================*/
.button{
    display: inline-block;
    background-color: $--first-color;
    color: #FFF;
    padding: 0 0.5rem ;
    border-radius: 6px;
    font-weight: $--font-medium;
    box-shadow: 0 10px 30px rgba(57, 56, 61, 0.21);
}

.button:hover{
    background-color: $--first-color-alt;
}

.button__icon{
    font-size: 1.25rem;
    margin-left: $--mb-0-5;
    transition: .3s;
}

.button--flex{
    display: inline-flex;
    align-items: center;
}


/*==================== ABOUT ====================*/
.about__img{
    width: 200px;
    border-radius: .5rem;
    justify-self: center;
    align-self: center;
}
.about__description{
    text-align: center;
    margin-bottom: $--mb-2-5;
}

.about__info{
    display: flex;
    justify-content: space-evenly;
    margin-bottom: $--mb-2-5;
}

.about__info-title{
    font-size: $--h2-font-size;
    font-weight: $--font-semi-bold;
    color: $--title-color
}

.about__info-name{
    font-size: $--smaller-font-size;
}
.about__info-title,
.about__info-name{
    display: flex;
    text-align: center;
}

.about__buttons{
    display: flex;
    justify-content: center;
}

/*==================== SKILLS ====================*/
.lblog__container{
    gap: 1.5;
    grid-template-columns: repeat(1, 1fr);
}
.lblog__content{
    cursor: pointer;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.04);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.04);
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-line-clamp: 4;

    transition: box-shadow 0.7s ease;

    &:hover {
        box-shadow: $--box-shadow !important;
    }
}
.lblog__featured{
    height: 200px !important;
    position: relative;
    z-index: 1;
    margin-bottom: $--mb-1 !important;

    img{
        height: 100%;
        width: 100%;
        background-size: cover;
    }
}
.lblog__modal{
    margin: $--mb-0-5 !important;
}
.lblog__title{
    margin-bottom: $--mb-0-5 !important;
    transition: 0.3s ease all;
    min-height: 65px;
}
.lblog__excerpt{
    -webkit-line-clamp: 4 !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    overflow: hidden !important;
    color: black;

}

/*==================== QUALIFICATION ====================*/


/*==================== SERVICES ====================*/


/* Active Modal */


/*==================== PORTFOLIO ====================*/


/*==================== PROJECT IN MIND ====================*/


/*==================== TESTIMONIAL ====================*/


/*==================== CONTACT ME ====================*/

// *==================== FOOTER ====================*/
.footer{
    padding-top: 2rem;
}

.footer__container{
    row-gap: 3.5rem;
    max-width: 1140px;
}
.footer__bg{
    background-color: $--first-color-second;
    padding: 2rem 0 3rem;
}

.footer__title{
    font-size: $--h1-font-size;
    margin-bottom: $--mb-0-25;
}

.footer__subtitle{
    font-size: $--small-font-size;
}

.footer__links{
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
}
.footer__link:hover{
    color: $--first-color-lighter;
}

.footer__social{
    font-size: 1.25rem;
    margin-right: $--mb-1-5;
}

.footer__social:hover{
    color: $--first-color-lighter;
}

.footer__copy{
    font-size: $--smaller-font-size;
    text-align: center;
    color: $--text-color-light;
    margin-top: $--mb-3;
}

.footer__title,
.footer__subtitle,
.footer__link,
.footer__social{
    color: #FFF;
}

/*========== SCROLL UP ==========*/
.scrollup{
     position: fixed;
     right: 1rem;
     bottom: -20%;
     background-color: #fb434f;
     opacity: .8;
     padding: 0 .3rem;
     border-radius: .4rem;
     z-index: $--z-tooltip;
     transition: .4s;
     box-shadow: 0 1px 20px 1px #fb434f;
 }
.scrollup:hover{
    background-color: #fb435f;
    box-shadow: 0 8px 25px -8px rgba(var(--vs-danger),1);
}

.scrollup__icon{
     font-size: 1.5rem;
     color:  #FFF;
}
/* Show scroll */
.show-scroll{
    bottom: 5rem;
}
/*==========  BLOG CONTENT ==========*/
.feadture_image{
    padding: 0;
    margin: 0;
    img{
        display: grid !important;
        width: 100% !important;
        height: auto !important;
        max-height: 350px;
       // border-radius: .5rem !important;
    }
}

.post__container{
    max-width: 1140px;
    gap: 1.5rem;
    // padding-top: 3.5rem;
    align-items: self-start !important;

    .post__left-content {

    }
    .post__right-content{

    }

    .post__data{
        font-size: $--normal-font-size;
    }

    .artical_body{
        padding-top: 1rem;
        img{
            width: 100%;
            height: auto;
            border-radius: 5px;
        }

        // .nws3_sec4{
        //     .list_item_btm_img{
        //         display: flex !important;
        //         width: 100% !important;
        //         height: auto !important;
        //     }
        // }

    }
    iframe{

        width: 100% !important;
        border-radius: 5px;
    }
    .blog_post_text{
        line-height: 1.6rem;
        outline: none;
        padding: 0.7em 0;
        color: black !important;
    }


    .blog_image{
        padding: 1em 0;
    }
    .blog_code{
        display: grid !important;
        width: 100%;
        padding: 1em 0;
        pre, code{
            overflow: auto !important;
            white-space: pre !important;
            max-height: 30em !important;
        }
    }
    blockquote {
        padding: 10px;
        padding-top: 10px !important;
        position: relative;
        padding: 1em 0;
        border-radius: 3px;
        //margin: 40px !important;


        .spcl_line_p {
            margin-top: 5px !important;
        }
        p {
            margin-top: 5px !important;
            font-family: "Utopia-italic" !important;
            font-size: 16px  !important;
            font-weight: 700px  !important;
            text-align: left  !important;
        }
    }
    .otro-blockquote {
        font-size:16px !important;
        // width: 100%;
       // margin: 50px auto;
        font-family: Open Sans;
        font-style: italic;
        color: #555555;
        padding: 1.6em 30px 1.2em 40px;
        border-left: 4px solid $--first-color;
        line-height: 1.6;
        position: relative;
        background: #EDEDED;
        &::before {
            font-family: Arial;
            content: "\201C";
            color: $--first-color;
            font-size: 3em;
            position: absolute;
            left: 10px;
            top: -10px;
        }
        span {
            display: block;
            color: #333333;
            font-style: normal !important;
            font-weight: bold !important;
            margin-top: 1em;
        }
    }

    .blog_caption, .list_item_btm_text{
        font-size: 13px !important;
        font-style: italic !important;
    }

    .post_tags {
        .post_tags_ul {
            display: flex;
            flex-wrap: wrap;

            padding: 0px;
            margin: 0px;

            margin-top: 10px;

            list-style: none !important;

            li {
                display: list-item;
                text-align: -webkit-match-parent;

                a {
                    cursor: pointer;
                    color: $--first-color;
                    // #e2127a;
                    font-size: 11px;
                    font-weight: 700 !important;

                    border-radius: 12px !important;

                    //border: none;

                    padding: 7px 13px;
                    margin: 0 7px 7px 0;
                    display: inline-block;

                    text-decoration: none;
                    background: #f3f3f3;

                    text-transform: uppercase;

                    transition: 0.3s all ease;

                    &:hover {
                        text-decoration: none;
                        background-color: $--hover-background;
                    }
                }
            }
        }
    }

}

.custome-pagination{
    display: block;
    padding: 2.4rem;
    text-align: center;
}
.pager{

    display: inline-block !important;
    padding: 0;
    margin: 20px 0;
    text-align: center;
    border-radius: 4px;


    a{
        color: black !important;
    }
    li{
        display: inline-block;
        margin: 0 5px;
        line-height: 1.5;
        padding: 0.4rem 1rem;
        font-weight: 400;
        color: black !important;
        border-radius: 5px;
        transition: box-shadow 0.7s ease;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.04);
        background: #fafaf6;

        &:hover {
            box-shadow: $--box-shadow !important;
        }


    }

    .disabled{
        display: none !important;
    }


    .active, .my-active{
        border-color: white;
        border-width: 5px !important;
        background:  rgba($--first-color, 0.3);
    }
}

.post__social--buttons{
    display: flex;
    justify-content: center;
}

.post__related-container{
    padding: 10px;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.04);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.04);
    -webkit-border-radius: 3px;
    border-radius: 8px;
    border: 1px solid #eee;
}


.featured-image{
    img{
        display: block;
        width: 100%;
        height: auto;
        border-radius: .5rem;
    }
}


.post__related-modal{
    gap: 0.5rem;
}


.post__related-main {
    cursor: pointer;
    padding: 6px;
    border-radius: 8px !important;
    gap: 0.5rem;
    grid-template-columns: 0.3fr 0.7fr;

    &:hover {
        text-decoration: none;
        background-color: $--hover-background;
    }


    .post__item-left {
        height: 80px !important;
    }
    .image {
        height: 100% !important;
        width: 100% !important;
        margin-right: 9px;
        background-size: cover;
        background-position: center center;
        border: 2px solid $--color-light;
        border-radius: 8px;
    }

    .content {
        flex: 1 1 auto;
        overflow: hidden;
        padding: 0px;
        .popular-title {
            padding: 0px;
            font-size: 14px;
            font-weight: 700;
            margin-top: 0px;
        }
    }


}





// /*========== SCROLL BAR ==========*/
// ::-webkit-scrollbar{
//     width: .60rem;
//     background-color: $--scroll-bar-color;
//     border-radius: .5rem;
// }

// ::-webkit-scrollbar-thumb{
//     background-color: $--scroll-thumb-color;
//     border-radius: .5rem;
// }

// ::-webkit-scrollbar-thumb:hover{
//     background-color: $--text-color-light;
// }


/*==================== MEDIA QUERIES ====================*/
/* For small devices */
@media screen and (min-width: 111px) {
    .post__social--buttons{
        display: grid !important;
        justify-content: inherit !important;
        row-gap: .5rem;
        width: 1rem;
    }

    .nav__link{
        font-size: $--small-font-size;
    }

}
@media screen and (max-width: 350px) {
    .container{
        margin-left: $--mb-1;
        margin-right: $--mb-1;
    }

    .nav__menu{
        padding: 2rem .25rem 4rem;
    }

    .nav__list{
        column-gap: 0;
    }
    .home__content{
        grid-template-columns: .25fr 3fr ;
    }
    .home__blob{
        width: 180px;
    }


}

@media screen and (min-width: 351px) {
    .post__social--buttons{
        display: flex !important;
        justify-content: inherit !important;
    }


}

/* For medium devices */
@media screen and (min-width: 568px) {
    .home__content{
        grid-template-columns: max-content 1fr 1fr;
    }
    .home__data{
        grid-column: inherit;
    }
    .home__img{
        order: 1;
        justify-self: center;
    }
    .about__container{
        grid-template-columns: repeat(2, 1fr);
    }

    .footer__container{
        grid-template-columns: repeat(2, 1fr);
    }

    .lblog__container{
        gap: 1.5;
        grid-template-columns: repeat(2, 1fr);
    }



    iframe{
        height: 350px !important;
    }

    .post__related-modal{
        grid-template-columns: repeat(2, 1fr);
    }

}




/* For medium devices */
@media screen and (min-width: 768px) {
    .container{
        margin-left: auto;
        margin-right: auto;
    }
    body{
        margin: 0;
    }

    .section{
        padding: 6rem 0 2rem;
    }
    .section__subtitle{
        margin-bottom: 4rem;
    }

    .header{
        top: 0;
        bottom: initial;
    }

    .header,
    .main,
    .footer__container{
        padding: 0 1rem;
    }

    .nav{
        height: #{$--header-height} + 1.5rem;
        column-gap: 1rem;
    }
    .nav__icon,
    .nav__close,
    .nav__toggle{
         display: none;
    }

    .nav__link{
        font-size: $--normal-font-size;
    }

    .nav__list{
        display: flex;
        column-gap: 2rem;
    }

    .nav__menu{
        margin-left: auto;
    }

    .home__container{
        row-gap: 5rem;
    }
    .home__content{
        padding-top: 5.5rem;
        column-gap: 2rem;
    }

    .home__blob{
        width: 270px;
    }

    .home__scroll{
        display: block;
    }

    .home__scroll-button{
        margin-left: 3rem;
    }

    .about__container{
        column-gap: 5rem;
    }

    .about__img{
        width: 350px;
    }
    .about__description{
        text-align: initial;
    }

    .about__info{
        justify-content: space-between;
    }

    .about__buttons{
        justify-content: initial;
    }

    .footer__container{
        grid-template-columns: repeat(3, 1fr);
    }
    .footer__bg{
        padding: 3rem 0 3.5rem;
    }
    .footer__links{
        flex-direction: row;
        column-gap: 2rem;
    }

    .footer__socials{
        justify-self: flex-end;
    }

    .post__container{
        margin-left: auto;
        margin-right: auto;
    }

    iframe{
        height: 450px !important;
    }




}

@media screen and (min-width: 980px) {
    .post__container{
        padding-top: 4rem;
        align-items: center;
        grid-template-columns: 8fr 4fr;
    }
    .post__related-modal{
        grid-template-columns: repeat(1, 1fr);
    }
}

/* For large devices */
@media screen and (min-width: 1024px) {


    .header,
    .main,
    .footer__container{
        padding: 0;
    }
    .home__blob{
        width: 320px;
    }
    .home__social{
        transform: translateX(-6rem);
    }

    .nav{
        max-width: 980px;
    }

    .lblog__container{
        gap: 1.5;
        grid-template-columns: repeat(3, 1fr);
        max-width: 980px;
    }

    .post__container{
        max-width: 980px;
    }


    .footer__container{
        row-gap: 3.5rem;
        max-width: 980px;
    }
}


/* For large devices */
@media screen and (min-width: 1200px) {

    .nav{
        max-width: 1140px;
    }

    .lblog__container{
        gap: 1.5;
        grid-template-columns: repeat(3, 1fr);
        max-width: 1140px;
    }

    .post__container{
        max-width: 1140px;
    }

    .footer__container{
        row-gap: 3.5rem;
        max-width: 1140px;
    }
}

